import moment from "moment"

export const getDifferenceDays = dateMatch => {
  const miliseconds = 24 * 60 * 60 * 1000
  const today = new Date()
  const match = new Date(dateMatch)
  const milisecondsDifference = Math.abs(match.getTime() - today.getTime())
  const daysDifference = Math.round(milisecondsDifference / miliseconds)

  return daysDifference
}

export const formatDate = string => {
  if (string.length < 9) {
    const fragment = string.split("") || []
    const formated = fragment.reduce((acum, item, i) => {
      if ((i === 2 || i === 4) && item !== "/") acum.push("/")
      acum.push(item)
      return acum
    }, [])
    return formated.join("")
  }
  return ""
}

export const formatDOB = (value) => {
  const cleanValue = value.replace(/\D/g, ""); // Remove non-digit characters
  let formattedDate = "";

  if (cleanValue.length <= 2) {
    formattedDate = cleanValue;
  } else if (cleanValue.length <= 4) {
    formattedDate = `${cleanValue.slice(0, 2)}/${cleanValue.slice(2)}`;
  } else {
    formattedDate = `${cleanValue.slice(0, 2)}/${cleanValue.slice(2, 4)}/${cleanValue.slice(4, 8)}`;
  }

  return formattedDate;
};


export const validateEmail = data => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  // return re.test(String(data.email).toLowerCase())
  const email = data.email
  return re.test(String(email).toLowerCase())
}

export const formatDateRange = rangoFecha => {
  if (!rangoFecha) return
  const monthNames = {
    January: "Enero",
    February: "Febrero",
    March: "Marzo",
    April: "Abril",
    May: "Mayo",
    June: "Junio",
    July: "Julio",
    August: "Agosto",
    September: "Septiembre",
    October: "Octubre",
    November: "Noviembre",
    December: "Diciembre",
  }
  const startDate = moment(rangoFecha[0])
  const endDate = moment(rangoFecha[1])
  let formattedDate
  if (
    startDate.date() === endDate.date() &&
    startDate.month() === endDate.month() &&
    startDate.year() === endDate.year()
  ) {
    formattedDate = `${startDate.date()}, ${
      monthNames[startDate.format("MMMM")]
    } ${startDate.year()}`
  } else if (
    startDate.month() === endDate.month() &&
    startDate.year() === endDate.year()
  ) {
    formattedDate = `${startDate.date()}-${endDate.date()}, ${
      monthNames[startDate.format("MMMM")]
    } ${startDate.year()}`
  } else {
    formattedDate = `${startDate.date()} ${
      monthNames[startDate.format("MMMM")]
    } - ${endDate.date()} ${
      monthNames[endDate.format("MMMM")]
    } ${endDate.year()}`
  }
  return formattedDate
}
