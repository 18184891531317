import { Link, navigate } from "gatsby"
import React, { useState, useEffect } from "react"
import Bowser from "bowser"
import guitarIcon from "../../images/quilmesMusica/svg/quilmes-web-04.svg"
import quilmesIcon from "../../images/quilmesMusica/svg/quilmes-web-05.svg"
import pinIcon from "../../images/quilmesMusica/svg/quilmes-web-23.svg"
import closeIcon from "../../images/quilmesMusica/svg/quilmes-web-17.svg"
import styles from "../../styles/headerEvents.module.scss"
import { IsMobileDevice } from "../../../utils/ismobile"

const HeaderEvents = () => {
  const [isMobile, setIsMobile] = useState(false)
  const [browserName, setBrowserName] = useState("")

  useEffect(() => {
    setIsMobile(IsMobileDevice())
    const {
      parsedResult: {
        browser: { name },
      },
    } = Bowser.getParser(window.navigator.userAgent)
    setBrowserName(name)
  }, [])

  function handleClose() {
    navigate("/quilmesfestivales")
  }
  return (
    <>
      {!isMobile ? (
        <header className={styles.header}>
          <div>
            <img
              className={styles.leftGuitar}
              src={guitarIcon}
              alt="guitarIcon"
            />
          </div>

          <div>
            <Link to={`/quilmesfestivales`}>
              <img
                className={styles.logo}
                src={quilmesIcon}
                alt="quilmesIcon"
              />
            </Link>
          </div>

          <div className={styles.lastContainer}>
            <img className={styles.logo} src={guitarIcon} alt="guitarIcon" />
            <div
              className={styles.menuButton}
              onClick={() => navigate(`/quilmesfestivales`)}
            >
              <img className={styles.pinIcon} src={pinIcon} alt="pin icon" />
              <p>VER MAPA</p>
            </div>
          </div>
        </header>
      ) : (
        <header className={styles.header}>
          <div style={{ paddingTop: "50px" }}>
            <Link to={`/quilmesfestivales`}>
              <img
                className={styles.logoMobile}
                src={quilmesIcon}
                alt="quilmesIcon"
              />
            </Link>
          </div>

          <div
            className={`${styles.menuButton} ${
              browserName === "Chrome"
                ? styles.chrome
                : browserName === "Safari"
                ? styles.safari
                : ""
            }`}
            onClick={() => navigate(`/quilmesfestivales`)}
          >
            <img className={styles.pinIcon} src={pinIcon} alt="pin icon" />
            <p>VER MAPA</p>
          </div>

          <img
            onClick={() => handleClose()}
            className={styles.closeIcon}
            src={closeIcon}
            alt="close-icon"
          />
        </header>
      )}
    </>
  )
}

export default HeaderEvents
