import React, { useState, useEffect } from "react"
import { graphql, navigate } from "gatsby"
import styles from "../../styles/quilmesEvent.module.scss"
// import { IsMobileDevice } from "../../../utils/ismobile"
import HeaderEvents from "../../components/quilmes-musica/headerEvents"

// Images
import pinIcon from "../../images/quilmesMusica/svg/quilmes-web-19.svg"
import CalendarIcon from "../../images/quilmesMusica/svg/quilmes-web-20.svg"
import { formatDateRange } from "../../../utils/functions"

const QuilmesEventList = ({ data }) => {
  // const [showMenu, setShowMenu] = useState(false)
  // const [isMobile, setIsMobile] = useState(false)
  const [selectedMonth, setSelectedMonth] = useState("all")
  const allEvents = data.allEventos.nodes
  const handleMonthClick = month => {
    setSelectedMonth(month.toLowerCase())
  }

  console.log("selectedMonth",selectedMonth)

  // const sortEvents = events => {
  //   return events.sort((a, b) => {
  //     const dateA = new Date(a.rangoFecha[0])
  //     const dateB = new Date(b.rangoFecha[0])
  //     return dateA - dateB
  //   })
  // }

  const sortedEventos = allEvents

  const months = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ]

  useEffect(() => {
    const slider = document.querySelector(".items")
    let isDown = false
    let startX
    let scrollLeft

    slider.addEventListener("mousedown", e => {
      isDown = true
      startX = e.pageX - slider.offsetLeft
      scrollLeft = slider.scrollLeft
    })
    slider.addEventListener("mousemove", e => {
      if (!isDown) return
      e.preventDefault()
      const x = e.pageX - slider.offsetLeft
      const walk = (x - startX) * 1 //scroll-fast
      slider.scrollLeft = scrollLeft - walk
      // console.log(walk)
    })
  })

  // useEffect(() => {
  //   setIsMobile(IsMobileDevice())
  // }, [])

  return (
    <div className={styles.container}>
      <HeaderEvents />

      <div className={styles.mainContainer}>
        <div className={styles.monthsRow}>
          <div onClick={() => handleMonthClick("all")}>
            <p
              style={{
                color:
                  selectedMonth.toLowerCase() === "all"
                    ? "white"
                    : "rgba(202, 201, 201, 0.444)",
              }}
            >
              TODOS
            </p>
          </div>
          {months.map(month => {
            // console.log("selectedMonth ==>>", selectedMonth)
            console.log("month ==>>", month)
            return (
              <div
                key={month}
                onClick={() => handleMonthClick(month.toLowerCase())}
                className="items"
                style={{
                  borderBottom:
                    selectedMonth.toLowerCase() === month ? "1px solid white" : "",
                }}
              >
                <p
                  style={{
                    color:
                      selectedMonth.toLowerCase() === month
                        ? "white"
                        : "rgba(202, 201, 201, 0.444)",
                  }}
                >
                  {month}
                </p>
              </div>
            )
          })}
        </div>

        <div className={styles.cardContainer}>
          {sortedEventos.map((evento, index) => {
            return (
              <div
                key={evento.titulo}
                className={`columns ${styles.testing} ${
                  selectedMonth === "all"
                    ? styles.selected
                    : selectedMonth.toLowerCase() !== evento.month
                    ? styles.notSelected
                    : ""
                }`}
                style={{
                  background: index % 2 !== 0 ? "#4b0c79" : "#00b2ee",
                  display:
                    selectedMonth === "all"
                      ? ""
                      : selectedMonth === evento.month.toLowerCase()
                      ? ""
                      : "none",
                }}
              >
                <div
                  className={styles.card}
                  // style={{
                  //   display:
                  //     selectedMonth === "all"
                  //       ? ""
                  //       : selectedMonth === event.month
                  //       ? "none"
                  //       : "",
                  // }}
                >
                  <div className={styles.topLeft}>
                    <div className={styles.calendarLogo}>
                      <img src={CalendarIcon} alt="calendar icon" />
                    </div>
                    {/* <div>
                      <p>{formatDateRange(evento.rangoFecha)}</p>
                    </div> */}
                  </div>
                  <img
                    src={evento.imgBack}
                    className={styles.image}
                    alt="image"
                  />

                  <div>
                    <div className={styles.bottomLeft}>
                      <p className={styles.pinTitle}>{evento.titulo}</p>
                      <div className={styles.locationContainer}>
                        <img
                          className={styles.pinIcon}
                          src={pinIcon}
                          alt="pin icon"
                        />
                        <p>{evento.localidad}</p>
                      </div>
                    </div>

                    <div
                      className={styles.bottomRight}
                      style={{
                        background: index % 2 === 0 ? "#4b0c79" : "#00b2ee",
                      }}
                      onClick={() => {
                        window.dataLayer.push({
                          event: "GAEvent",
                          event_category: "Content",
                          event_action: `Card`,
                          event_label: evento.titulo,
                          interaction: true,
                          component_name: "boton_ver_mas",
                          element_text: "Ver más",
                        })
                        navigate("/quilmesfestivales/formulario", {
                          state: { event: evento },
                        })
                      }}
                    >
                      <p>ver más</p>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default QuilmesEventList

export const data = graphql`
  query Musica2023Eventos {
    allEventos {
      nodes {
        id
        comoLlegar
        fecha
        imgBack
        latitud
        longitud
        localidad
        month
        provincia
        texto
        active
        titulo
      }
    }
  }
`
